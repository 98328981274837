<template>
    <div id="agendas">
        <div class="tabela-agendas">
            <!--botão para abrir a modal de cadastro de cliente-->
            <div class="col-12 novo-cliente">
                <v-btn class="primary-button" raised @click="createAgenda(1)" :disabled="$store.getters.user.group.key != 'company-attendant' ? ($store.getters.company_id == null ? true : false) : false">
                    Cadastrar Cliente
                </v-btn>
            </div>
            <!--tabela que ira conter a listagem dos clientes-->
            <CustomTable
                v-if="headers != ''"
                :action="'getClientes'"
                :getter="$store.getters.clientes"
                :headers="headers"
                :search="true"
                :title="'Clientes'"
                :icone="'fas fa-user-tie'"
                :pagination="true"
                :filters="filtros"
                ref="tabela"
            >
                <!--botão para ativar modal de edição do cliente-->
                <template v-slot:acoes="{ item }">
                    <v-btn class="primary-button" raised small @click="editarCliente(item.id, item.name_user_company, item.attendant_id, item.user.email, item.department_id)" :disabled="$store.getters.user.group.key != 'company-attendant' ? ($store.getters.company_id == null ? true : false) : false">
                        <i class="fas fa-cog"></i>
                        Editar
                    </v-btn>
                </template>
            </CustomTable>
            <!--modal para edição de um cliente-->
            <v-dialog v-model="dialog_cliente" persistent max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Cliente</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form ref="form_cliente">
                                <div class="row">
                                    <div class="col-12">
                                        <v-text-field
                                            :rules="[v => !!v || 'Campo nome obrigatório']"
                                            v-model="cliente.nome"
                                            label="Cliente"
                                            placeholder="Cliente"
                                            background-color="white"
                                            hide-details
                                            outlined
                                        />
                                    </div>
                                    <div v-if="cliente.id == ''" class="col-12">
                                        <v-select
                                            :items="numeros"
                                            item-text="name"
                                            item-value="id"
                                            v-model="cliente.contato"
                                            label="Telefone"
                                            placeholder="Telefone"
                                            background-color="white"
                                            hide-details
                                            outlined
                                        />
                                    </div>
                                    <div class="col-12">
                                        <v-text-field
                                            :readonly="cliente.id != ''"
                                            v-model="cliente.numero"
                                            label="Número do cliente"
                                            placeholder="Número do cliente"
                                            v-mask="cliente.numero != null ? (cliente.numero.length < 13 ? '+55 (##) ####-####' : '+55 (##) # ####-####') : ''"
                                            background-color="white"
                                            hide-details
                                            outlined
                                        />
                                    </div>
                                    <div class="col-12">
                                        <v-select
                                            :items="departamentos"
                                            item-text="name"
                                            item-value="id"
                                            v-model="cliente.departamento"
                                            label="Departamento para redirecionamento"
                                            placeholder="Departamento para redirecionamento"
                                            background-color="white"
                                            hide-details
                                            outlined
                                            clearable
                                            @change="atendentesDepartamento()"
                                        />
                                    </div>
                                    <div class="col-12">
                                        <v-select
                                            :items="atendentes"
                                            item-text="name"
                                            item-value="id"
                                            v-model="cliente.atendente"
                                            label="Atendente para Cliente"
                                            placeholder="Atendente para Cliente"
                                            background-color="white"
                                            hide-details
                                            outlined
                                            clearable
                                        />
                                    </div>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="closeCliente">
                            Cancelar
                        </v-btn>
                        <v-btn class="primary-button" small @click="enviarCliente">
                            Salvar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
            <Loader v-if="loading"/>
        </div>
    </div>
</template>
<!--funções para trazer e encaminhar os dados das modais e da tabela-->
<script>
    // importa o store
    import store from '@/store'
    // importa o componente de DialogMensagem
    import DialogMensagem from '@/components/DialogMensagem.vue'
    // importa o componente de Loader
    import Loader from '@/components/Loader.vue'
    // importa o componente de CustomTable
    import CustomTable from '@/components/CustomTable.vue'

    // exporta o componente
    export default{
        // nome do componente
        name: 'agendas',
        // componentes filhos
        components: {
            // componente de DialogMensagem
            DialogMensagem,
            // componente de CustomTable
            CustomTable,
            // componente de Loader
            Loader,
        },
        // dados do componente
        data: () => ({
            // variável para mostra a modal de edição dos dados dos clientes
            dialog_cliente: false,
            // variáve; para mostrar a moda de informação
            dialog_resposta: false,
            // variável para a mensagem de resposta
            resposta: {},
            // variável para o loading
            loading: false,
            // variável para os números 
            numeros: [],
            // variável para os departamentos
            departamentos: [],
            // variável para os atendentes
            atendentes: [],
            // variável para editar os dados do cliente
            cliente: {
                id: '', 
                nome: '', 
                contato: '',
                numero: '',
                departamento: '',
                atendente: '',
            },
            // variável para o cabeçalho da tabela
            headers: [
                {
                    value: 'name_user_company',
                    text: 'Cliente',
                    sortable: true,
                },
                {
                    value: 'company.name',
                    text: 'Empresa',
                    sortable: true,
                },
                {
                    value: 'department.name',
                    text: 'Departamento',
                    sortable: true,
                },
                {
                    value: 'attendant.name',
                    text: 'Atendente',
                    sortable: true,
                },
                {
                    value: 'acoes',
                    text: 'Ações',
                    sortable: false,
                },
            ],
            // variável para os filtros da tabela
            filtros: {
                perPage: 20,
            },
        }),
        // funções deste componente
        methods: {
            // função de inicio do componente
            async init(){
                // caso o usuário seja um atendente
                if(this.$store.getters.user.group.key == 'company-attendant'){
                    // seta a empresa na header
				    await store.dispatch('setCompanyId', this.$store.getters.user.companies[0].id)
                }
            },
            // função para abrir a modal de cadastro/edição de um contato
            async createAgenda(filtro){
                // coloca o componente como loading
                this.loading = true
                // chama a função para puxar os números
                await this.numerosEmpresa()
                // chama a função para puxar os departamentos
                await this.departamentosEmpresa()
                // verificação para previnir bug no vue
                if(filtro == 1){
                    // verificação para previnir bug no vue
                    this.cliente.numero = null
                }
                // abre a modal para editar o cliente
                this.dialog_cliente = true
                // retira o loading do componente
                this.loading = false
            },
            // função para puxar os números de telefone com base na empresa
            async numerosEmpresa(){
                // variável auxiliar
                var empresa_selecionada
                // caso o usuário seja um atendente 
                if(this.$store.getters.user.group.key == 'company-attendant'){
                    // atribui a empresa
                    empresa_selecionada = this.$store.getters.companies[0].id
                // caso seja um admin
                }else if(this.$store.getters.user.group.key == 'company-admin'){
                    // atribui a empresa selecionada
                    empresa_selecionada = this.$store.getters.company_id
                }
                // faz um dispatch para uma action no vuex para pegar os números
                var resp = await store.dispatch('getTelefoneSelect', empresa_selecionada)
                // caso o status seja 200 (deu certo)
                if (resp.status == 200){
                    // atribui os dados à variável local
                    this.numeros = resp.data.phones
                }
            },
            // função para puxar os departamentos com base na empresa
            async departamentosEmpresa(){
                // faz um dispatch para uma action no vuex para pegar os departamentos
                var resp = await store.dispatch('departamentosEmpresa')
                // caso o status seja 200 (deu certo)
                if(resp.status == 200){
                    // atribui os dados à variável local
                    this.departamentos = resp.data.departamentos
                }
            },
            // função para puxar os atendentes com base no departamento
            async atendentesDepartamento(){
                // caso haja um departamento selecionado
                if(this.cliente.departamento != '' && this.cliente.departamento != null){
                    // faz um dispatch para uma action no vues para pegar os atendentes passando o departamento
                    var resp = await store.dispatch('atendentesDepartamento', this.cliente.departamento)
                    // caso a requisição seja concluída com sucesso
                    if(resp.status == 200){
                        // atribuímos os dados à variável local
                        this.atendentes = resp.data.atendentes
                    }
                }
            },
            // função para salvar os dados do cliente
            async enviarCliente(){
                let resp = {}
                // caso o campo do formulário esteja válido
                if(this.$refs.form_cliente.validate()){
                    // coloca o componente como loading
                    this.loading = true
                    // coleta os dados do cliente
                    let dados = {
                        // coleta o telefone da empresa
                        phone_id: this.cliente.contato.toString(),
                        // coleta o número do cliente
                        email: this.cliente.numero.replace(/\D/g,''),
                        // coleta o nome do cliente
                        name: this.cliente.nome,
                        // coleta o departamento responsável pelo cliente
                        department_id: this.cliente.departamento != null && this.cliente.departamento != '' ? this.cliente.departamento.toString() : '',
                        // coleta o atendente responsável pelo cliente
                        attendant_id: this.cliente.atendente != null && this.cliente.atendente != '' ? this.cliente.atendente.toString() : ''
                    }
                    // caso o cliente ja exista
                    if(this.cliente.id){
                        // armazena os dados para realizar a atualização
                        let date_update = {
                            dados_cliente: dados,
                            id: this.cliente.id
                        }
                        // rota para a atualização dos dados do cliente
                        resp = await store.dispatch('putCliente', date_update)
                    }else{
                        // rota para a criação do cliente
                        resp = await store.dispatch('postCliente', dados)
                    }
                    // caso algo tenha dado errado
                    if(resp.status != 200 && resp.status != 201){
                        // atribui o título da mensagem
                        this.resposta.titulo = 'Algo deu errado!'
                        // atribui o corpo da mensagem
                        this.resposta.mensagem = await resp.data.message || resp.data.error
                        // mostra a mensagem
                        this.dialog_resposta = true
                    // caso tenha dado tudo certo
                    }else{
                        // atribui o título da mensagem
                        this.resposta.titulo = 'Cliente ' + (this.cliente.id ? 'editado' : 'cadastrado')
                        // atribui o corpo da mensagem
                        this.resposta.mensagem = 'Cliente ' + (this.cliente.id ? 'editado' : 'cadastrado') + ' com sucesso!'
                        // fecha a modal de cliente
                        this.closeCliente()
                        // mostra a mensagem
                        this.dialog_resposta = true
                    }
                    // retira o loading do componente
                    this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
                }
            },
            // função para editar um cliente
            async editarCliente(id, nome, atendente, numero, departamento){
                // coloca o componente como loading
                this.loading = true
                // atribui o id do cliente
                this.cliente.id = id
                // atribui o nome do cliente
                this.cliente.nome = nome
                // atribui o número do cliente
                this.cliente.numero = numero.replace('+55','')
                // atribui o departamento do cliente
                this.cliente.departamento = departamento
                // atribui o atendente do cliente
                this.cliente.atendente = atendente
                // caso haja departamento
                if(this.cliente.departamento != null){
                    // chama a função do atendente
                    await this.atendentesDepartamento()
                }
                // chama a função para abrir a modal
                this.createAgenda(2)
                // retira o loading do componente
                this.loading = false
            },
            // função para fechar a modal de clientes
            closeCliente(){
                // fecha a modal
                this.dialog_cliente = false
                // limpa os dados locais do cliente
                this.cliente = {
                    id: '',
                    nome: '',
                    contato: '',
                    numero: '',
                    departamento: '',
                    atendente: '',
                }
            },
        },
        mounted(){
            this.init()
        },
    }
</script>
<!--scss do módulo-->
<style lang="scss" scoped>
    #agendas{
        display: flex;
        max-height: 100%;
        padding: 24px;
        flex-wrap: wrap;
        .tabela-agendas{
            width: 100%;
            background-color: #fff;
            border-radius: 10px;
            .novo-cliente{
                display: flex;
                justify-content: flex-end;
                padding-bottom: 0;
                button{
                    i, svg{
                        margin-right: 10px;
                    }
                    text-transform: capitalize;
                }
            }
        }
    }
    .v-dialog{
        .v-card{
            background-color: #f9f9f9;
        }
        .headline{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-weight: 600;
            color: #11263C;
        }
    }
</style>
